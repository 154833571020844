// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-faq-en-js": () => import("./../../../src/pages/faq.en.js" /* webpackChunkName: "component---src-pages-faq-en-js" */),
  "component---src-pages-find-a-trial-en-js": () => import("./../../../src/pages/find-a-trial.en.js" /* webpackChunkName: "component---src-pages-find-a-trial-en-js" */),
  "component---src-pages-hcp-en-js": () => import("./../../../src/pages/hcp.en.js" /* webpackChunkName: "component---src-pages-hcp-en-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-results-en-js": () => import("./../../../src/pages/results.en.js" /* webpackChunkName: "component---src-pages-results-en-js" */),
  "component---src-pages-sitemap-en-js": () => import("./../../../src/pages/sitemap.en.js" /* webpackChunkName: "component---src-pages-sitemap-en-js" */),
  "component---src-pages-trial-details-en-js": () => import("./../../../src/pages/trial-details.en.js" /* webpackChunkName: "component---src-pages-trial-details-en-js" */),
  "component---src-pages-what-is-a-clinical-trial-en-js": () => import("./../../../src/pages/what-is-a-clinical-trial.en.js" /* webpackChunkName: "component---src-pages-what-is-a-clinical-trial-en-js" */),
  "component---src-pages-why-participate-en-js": () => import("./../../../src/pages/why-participate.en.js" /* webpackChunkName: "component---src-pages-why-participate-en-js" */)
}

